
.inputField {
    padding: .5rem;
    border-radius: 5px;
    margin-top: .2rem;
    border: 1px solid #ccc;
}

.inputWrapper {
    display: flex;
    margin-right: .5rem;
    flex-direction: column;
    width: 100%;
}

.filterWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
}