.meal {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
}

.meal h3 {
    margin: 0 0 0.25rem 0;
}

.description {
    font-style: italic;
}

.productCategory {
    background-color: #080b50;
    color: #fff;
    padding: .1rem .5rem;
    border-radius: 14px;
    margin-right: .5rem;
}

.mealTitle {
    margin-left: .5rem;
}

.mealWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.productImage {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 14px;
}

.price {
    margin-top: 0.25rem;
    font-weight: bold;
    color: #080b50;
    font-size: 1.25rem;
}