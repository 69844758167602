.footer{
    padding: 1rem 0;
    background-color: #3e4095;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerTitle{
    color: #fff;
}