.form {
  text-align: right;
}

.form button {
  font: inherit;
  cursor: pointer;
  background-color: #3e4095;
  border: 1px solid #3e4095;
  color: white;
  padding: 0.25rem 2rem;
  border-radius: 14px;
  font-weight: bold;
}

.form button:hover,
.form button:active {
  background-color: #080b50;
  border-color: #080b50;
}