.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #3e4095;
    padding: 1rem 0;
    margin: 1rem 0;
}

.cart-item h2 {
    margin: 0 0 0.5rem 0;
    color: #363636;
}

.summary {
    width: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price {
    font-weight: bold;
    color: #3e4095;
}

.amount {
    font-weight: bold;
    border: 1px solid #ccc;
    padding: 0.25rem 0.75rem;
    border-radius: 6px;
    color: #363636;
    margin-left: .5rem;
}

.actions {
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .actions {
        flex-direction: row;
    }
}

.cart-item button {
    font: inherit;
    font-weight: bold;
    font-size: 1.25rem;
    color: #3e4095;
    border: 1px solid #3e4095;
    width: 3rem;
    text-align: center;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
    margin-left: 1rem;
    margin: 0.25rem;
}

.cart-item button:hover,
.cart-item button:active {
    background-color: #3e4095;
    color: white;
}

.productImage {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 14px;
}

.productWrapper {
    display: flex;
    align-items: center;
}

.productInfo {
    margin-left: .5rem;
}